import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '../../Tooltip';
import {inputStyles} from './styles';
import {type InputWrapperProps} from './types';

function InputWrapper({
  children,
  label,
  labelVariant,
  tooltip,
  error,
  hideError,
  endSlot,
  labelSlot,
  hideLabel,
}: InputWrapperProps) {
  // TODO(cboltt): Refactor to instead pass LabelProps
  const hasTypographyVariant = labelVariant !== 'bold' && labelVariant !== 'normal';

  return (
    <Box sx={inputStyles.root}>
      {!hideLabel && (
        <Box sx={inputStyles.labelRow}>
          <Box sx={inputStyles.label}>
            <Typography
              color="text.primary"
              fontWeight={labelVariant === 'bold' ? '600' : '500'}
              variant={hasTypographyVariant ? labelVariant : undefined}
              mr={1}
            >
              {label}
            </Typography>
            {tooltip && (
              <Tooltip title={tooltip}>
                <InfoOutlinedIcon fontSize="inherit" sx={{mb: -0.25}} />
              </Tooltip>
            )}
          </Box>
          {labelSlot}
        </Box>
      )}
      <Box sx={inputStyles.inputRow}>
        {children}
        {endSlot}
      </Box>
      {!hideError && (
        <Typography variant="body2" color="error" sx={inputStyles.error}>
          {error}
        </Typography>
      )}
    </Box>
  );
}

export default InputWrapper;
